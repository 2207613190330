import React from 'react'
import {
    useTable, usePagination,
    useRowSelect, useSortBy, useFilters,
    useGlobalFilter, useAsyncDebounce
} from 'react-table'
import { faPlus, faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from 'react-number-format';

import * as rcSumAction from '../../../constaints/rclistaction';
import RCSumDropDown from '../RCSumDropDown/index';
import RCSumDropDownAction from '../RCSumDropDownAction';
import RCSumDropDownExp from '../RCSumDropDownExp';
import './sumTableItem.css'
import './sumtitle.css'
import { useSelector } from 'react-redux';
import RCSumDropDownService from '../RCSumDropDownService';
// Create an editable cell renderer

const EditableCell = ({
    value: initialValue,
    data,
    edit,
    type,
    isReception,
    listRoom,
    focusInputName,
    setFocusInputName,
    branchID,
    row: { values },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}) => {
    const findindex = data.findIndex(({ ID }) => ID === values.ID);
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [sub, setSub] = React.useState();
    const [name_sub1, setSubName1] = React.useState(data[findindex].name_sub1);
    const [name_sub2, setSubName2] = React.useState(data[findindex].name_sub2);
    const [name_sub3, setSubName3] = React.useState(data[findindex].name_sub3);
    const [name_sub4, setSubName4] = React.useState(data[findindex].name_sub4);
    const [name_sub5, setSubName5] = React.useState(data[findindex].name_sub5);
    const [sub1, setSub1] = React.useState(data[findindex].sub1);
    const [sub2, setSub2] = React.useState(data[findindex].sub2);
    const [sub3, setSub3] = React.useState(data[findindex].sub3);
    const [sub4, setSub4] = React.useState(data[findindex].sub4);
    const [sub5, setSub5] = React.useState(data[findindex].sub5);
    const [currentId, setCurrentID] = React.useState("");
    const checkDebit = type === "findInvoice" ? false : data[findindex].action.toLowerCase().includes("debit");
    const checkCancel = type === "findInvoice" ? false : data[findindex].action.toLowerCase().includes("cancel");
    const checkPaidAt = type === "findInvoice" ? false : data[findindex].action.toLowerCase().includes("paid at");
    const itemID = values.ID;

    const onChange = e => {
        setValue(e.target.value);
    }

    const onChangeRateExpense = (value, itemID, target) => {
        updateMyData(value, values.ID, "exp_rate_type");
    }

    // We'll only update the external data when the input is blurred
    const onBlur = (e) => {
        updateMyData(e.target.value, itemID, id);
        // setCurrentID("");
    }

    const onChangeSub = (value, target) => {
        updateMyData(value, itemID, target);
        switch (target) {
            case "name_sub1": return setSubName1(value);
            case "name_sub2": return setSubName2(value);
            case "name_sub3": return setSubName3(value);
            case "name_sub4": return setSubName4(value);
            case "name_sub5": return setSubName5(value);
            default: return ""
        }
    }

    const onBlurSub = (value, itemID2, target) => {
        updateMyData(value, itemID, target);
        switch (target) {
            case "name_sub1": return setSubName1(value);
            case "name_sub2": return setSubName2(value);
            case "name_sub3": return setSubName3(value);
            case "name_sub4": return setSubName4(value);
            case "name_sub5": return setSubName5(value);
            default: return ""
        }
    }

    const onChangeStatusSub = (value, itemID2, target) => {
        updateMyData(value, itemID, target);
        switch (target) {
            case "sub1": return setSub1(value);
            case "sub2": return setSub2(value);
            case "sub3": return setSub3(value);
            case "sub4": return setSub4(value);
            case "sub5": return setSub5(value);
            default: return ""
        }
    }

    const onFocus = () => {
        setFocusInputName(false);
        setCurrentID("");
    }

    const onChangeRoom = (value) => {
        setFocusInputName(true);
        updateMyData(value, itemID, id);
        setCurrentID(itemID);
    }

    const onClickRoomSuggest = (value) => {
        setFocusInputName(false);
        setCurrentID("");
        updateMyData(value, itemID, id);
    }

    //  Set Blur
    const renderSuggestRoom = (value) => {
        var result = [];
        if (value.length >= 3) {
            if (listRoom.includes(value.toLocaleUpperCase())) {
                result = [];
                result.push(<li onClick={() => onClickRoomSuggest(value)} className="table-room_item" >{value}</li>)
            }
        } else if (value.length <= 2 && value.length > 0) {
            listRoom.forEach((room, index) => {
                if (String(room).slice(0, 2) === value.toLocaleUpperCase()) {
                    result.push(<li onClick={() => onClickRoomSuggest(room)} className="table-room_item"> {room}</ li>);
                }
            })
        }
        return result;
    };

    const renderErrorRoom = (value) => {
        var result = [];
        if (value.length <= 2 && value.length > 0 && (data[findindex].HI !== "")) {
            result = (<h5 className="table-room_error">! Error Room</h5>)
        } else if (value.length === 0 && (data[findindex].HI !== "")) {
            result = (<h5 className="table-room_error">! input room</h5>)
        }
        else if (value.length >= 3) {
            if (!listRoom.includes(data[findindex].room)) {
                result = (<h5 className="table-room_error">! Error Room</h5>)
            }
        }
        return result;
    }

    const renderSub = (sub, number) => {
        return <div className={(!data[findindex][sub] ? 'op-hidden' : '') + ' op-text-left  sub_td2'}>
            {edit ? data[findindex].SI === "" ? <RCSumDropDown
                disabled={!edit}
                item={data[findindex]}
                show={data[findindex]["name_" + sub]}
                subname={number}
                onChange={onChangeSub}
                onBlur={onBlurSub}
                onChangePlus={onChangeStatusSub}
                onChangeMinus={onChangeStatusSub} /> :
                <RCSumDropDownService
                    onChange={onChangeSub}
                    onBlur={onBlurSub}
                    subname={number}
                    target={"name_" + sub}
                    disabled={!edit}
                    item={data[findindex]}
                    onChangeItem={updateMyData}
                    onChangePlus={onChangeStatusSub}
                    onChangeMinus={onChangeStatusSub}
                />
                : <div className="op-text-left ml-3">{data[findindex]["name_" + sub]}</div>}
        </div>
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    switch (id) {
        case "version": return <div>
            <div>{value}</div>
            {
               ( type !=="takeInvoiceOnDate" && type !=="findInvoice") && <div
               style={{ color: "blue", cursor: 'pointer' }}
               onClick={() =>
                   window.open(`/reception_sum/${branchID}/${data[findindex].sum_credit}/${value}`)}
                  >(link)</div>
            }
        </div>
        case "HI": return <input disabled={!edit || data[findindex].SI !== ""} value={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
        case "SI": return <input disabled={!edit || data[findindex].HI !== ""} value={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
        case "checkin": case "checkout": return <input disabled={!edit} type="date" value={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
        case "vnd": case "usd": case "yen": case "vcb":
            return <NumberFormat
                disabled={!edit || checkDebit || checkPaidAt || checkCancel}
                type="text"
                thousandSeparator={true}
                value={(checkDebit || checkPaidAt || checkCancel) ? "" : value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur} />
        case "vcb_other": return <div className="d-flex">
            <NumberFormat
                disabled={!edit || checkDebit || checkPaidAt || checkCancel}
                type="text"
                thousandSeparator={true}
                value={(checkDebit || checkPaidAt || checkCancel) ? "" : value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur} />
            <div style={{
                width: branchID === 11 ? '0%' : '50%',
                display: branchID === 11 ? 'none' : 'block'
            }} >
                {edit ? <RCSumDropDownExp
                    // item={value}
                    branchID={branchID}
                    item={data[findindex]}
                    type="vcb_other_rate_type"
                    onChangeItem={updateMyData} /> : <div>{data[findindex].vcb_other_rate_type}</div>}
            </div>
        </div>
        case "room": return <div className="table-room"  >
            <input
                disabled={!edit}
                value={data[findindex].room === "false" ? 0 : data[findindex].room}
                onChange={e => onChangeRoom(e.target.value)}
                onBlur={onBlur} />
            {(focusInputName && values.ID === currentId) ? <ul className="table-room_list" > {renderSuggestRoom(data[findindex].room)}</ul> : ""}
            {(focusInputName === false && isReception && type !== "Search") ? renderErrorRoom(data[findindex].room) : ""}
        </div>
        case "room_rate": return <NumberFormat
            disabled={!edit}
            thousandSeparator={true}
            value={value}
            onChange={onChange}
            prefix={'$'}
            onFocus={onFocus}
            onBlur={onBlur} />
        case "action": return edit ?
            <RCSumDropDownAction disabled={!edit} item={data[findindex]} onChangeItem={updateMyData} />
            : <div>{data[findindex].action}</div>
        case "exp": return <textarea
            style={{ height: '23px' }}
            onFocus={onFocus}
            disabled={!edit}
            className="op-text-left ml-3 "
            value={value}
            onChange={onChange}
            onBlur={onBlur} ></textarea>
        case "exp_rate": return branchID === 11 ? <NumberFormat
            disabled={!edit || checkDebit || checkPaidAt}
            type="text"
            thousandSeparator={true}
            value={checkDebit ? "" : checkPaidAt ? "" : value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur} /> :
            <div className="d-flex">
                <NumberFormat
                    style={{ width: '50%' }}
                    type={"text"}
                    disabled={!edit}
                    className={"op-text-right"}
                    thousandSeparator={true}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur} />
                <div style={{ width: '50%' }} >
                    {edit ?
                        <RCSumDropDownExp
                            // item={value}
                            branchID={branchID}
                            item={data[findindex]}
                            type="exp_rate_type"
                            // onChangeItem={updateMyData}
                            onChangeItem={onChangeRateExpense}
                        />
                        : <div>{data[findindex].exp_rate_type}</div>}
                </div>
            </div>
        case "name": return <div>
            <div className="d-flex op-text-left ml-3" style={{
                fontWeight: '600',
            }}>
                {/* !SI -> show input HI : SI -> Show Input Service */}
                {edit ? data[findindex].SI === "" ? <textarea
                    style={{
                        fontWeight: '600',
                    }}
                    onFocus={onFocus}
                    disabled={!edit}
                    className="op-text-left ml-3"
                    value={value.toLocaleUpperCase()}
                    onChange={onChange}
                    onBlur={onBlur} ></textarea>
                    : <RCSumDropDownService
                        target="name" disabled={!edit} item={data[findindex]} onChangeItem={updateMyData} />
                    : data[findindex].name}

                <FontAwesomeIcon
                    style={{
                        color: 'green',
                        display: 'block',
                        cursor: 'pointer',
                        opacity: '0.3'
                    }}
                    icon={faPlus}
                    className={(!edit ? "op-hidden" : "") + ' sub_td2-option'}
                    onClick={() => onChangeStatusSub(!sub1, itemID, "sub1")}
                />
            </div>
            {renderSub("sub1", 1)}
            {renderSub("sub2", 2)}
            {renderSub("sub3", 3)}
            {renderSub("sub4", 4)}
            {renderSub("sub5", 5)}
        </div>
        default: return <input disabled={!edit} value={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
    }
    //op-text-left
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center mb-2" style={{ margin: 'auto',zIndex: '99' }}>
            <h4 > Search:{' '}</h4>
            <input
                className="ml-2 search_input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Enter here key search"
            />
        </div>
    )
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {

        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
}

function Table({ columns, data, updateMyData, onSelected, isPrintModel, branchID, type, listDelete,
    handleDeleteItem, handCopyItem,
    edit, listRoom, focusInputName, setFocusInputName, isReception }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        edit,
        type,
        isReception,
        listRoom,
        branchID,
        listDelete,
        focusInputName,
        setFocusInputName,
        defaultColumn,
        updateMyData,
        handCopyItem,
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div className={(type === "Search" ? "op-hidden" : "") + " rp__select"} >
                            {/* <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                                onClick={() => onSelected("all")} /> */}
                        </div >
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div className={(type === "Search" ? "op-hidden" : "") + " rp__select"}>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()
                                }
                                onClick={() => onSelected(row.values.ID)
                                } />
                        </div >
                    ),
                },
                ...columns,
            ])
        }
    )
    //console.log("selectedFlatRows", selectedFlatRows);
    // onClick={() => onSelected(selectedFlatRows)}

    // Render the UI for your table

    const isFindInvoice = type === "findInvoice" || type==="takeInvoiceOnDate"
    return (
        <>
            <div className={"d-flex align-items-center"} style={isFindInvoice ? {} : {position:'fixed', top: '84px', zIndex:'12'}}>
                <div className={(listDelete.length === 0 || !edit) ? "op-hidden" : "mr-5 d-flex align-items-center"}
                    style={{ cursor: 'pointer' }}
                    onClick={handleDeleteItem}>
                    <FontAwesomeIcon
                        icon={faTrash}
                        className={"btn__add_icon"} />
                    <p className="ml-3" style={{ margin: "auto" }}>Delete</p>
                </div>

                <div className={(listDelete.length === 1 && edit) ? "mr-5 d-flex align-items-center" : "op-hidden"}
                    style={{ cursor: 'pointer' }}
                    onClick={handCopyItem}>
                    <FontAwesomeIcon
                        icon={faCopy}
                        className="btn__add_icon" />
                    <p className="ml-3" style={{ margin: "auto" }}>Copy</p>
                </div>

                <div
                    colSpan={visibleColumns.length}
                    style={{
                        textAlign: 'center',
                        alignItems: "center"
                    }}>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter} />
                </div>


                {/* <div className="rp-button-top">
                    <button className="btn btn-primary">Invoice On Date</button>
                    <button className="btn btn-primary">Find Invoice</button>
                 </div> */}

            </div>
            <table className={isFindInvoice ? "rp" :"rp rp__table"} {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr className="rp__tr"
                            {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    className={(column.id === "ID" ? "op-hidden " : "") + "rp__" + column.id + " rp_row-fix no-print"}
                                    //style={column.id === "action" ? {} : {}}
                                    style={{ position: isPrintModel !== null ? 'unset' : "sticky" }}
                                    {...column.getHeaderProps(column.id === "HI" ? column.getSortByToggleProps() : column.id === "SI" ? column.getSortByToggleProps() : "")}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row)
                        const findindex = data.findIndex(({ ID }) => ID === row.values.ID);
                        return (
                            <tr
                                className={rcSumAction.DEBIT_LINE_COLOR.includes(data[findindex].action) ? " bg_yellow" : ""}
                                {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            className={(cell.column.id === "ID" ? "op-hidden" : "") + " input__" + cell.column.id}
                                            {...cell.getCellProps()} >
                                            {cell.render('Cell')}
                                        </td>)
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

function RCSumTableItemReactTable(props) {
    // Cal API take Header Table
    const listCardSum = useSelector(state => state.listCardSum);
    const auth = useSelector(state => state.auth);
    const { isReception } = auth;
    const { card_1, card_2 } = listCardSum;
    const { branchID, Items, listDelete, type } = props;
    const { data, edit } = Items;
    const listRoom = useSelector(state => state.listRoom);
    const [focusInputName, setFocusInputName] = React.useState(false)
    const isPrintModel = document.getElementById("previewArea");
    var listRender = [];
    // findInvoice
    //console.log("type", type)
    function checkListRender() {
        if( type !== "findInvoice" && type!=="takeInvoiceOnDate") {
            if (type === "Search") {
                listRender.push(
                    {
                        Header: 'Sum Date',
                        accessor: 'sum_credit',
                    },
                    {
                        Header: 'Version',
                        accessor: 'version',
                    })
            }
            listRender.push(
                {
                    Header: 'Hotel Invoice',
                    accessor: 'HI',
                },
                {
                    Header: 'Service Invoice',
                    accessor: 'SI',
                },
                {
                    Header: 'Customer Name',
                    accessor: 'name',
                    SubCell: (cellProps) => (
                        <> {cellProps.value} </>)
                },
                {
                    Header: 'Room Number',
                    accessor: 'room',
                },
                {
                    Header: 'Unit Price	',
                    accessor: 'room_rate',
                },
                {
                    Header: 'Check in',
                    accessor: 'checkin',
                },
                {
                    Header: 'Check out',
                    accessor: 'checkout',
                },
                {
                    Header: 'Action',
                    accessor: 'action',
                },
                {
                    Header: branchID === 11 ? "USD" : 'VND',
                    accessor: branchID === 11 ? 'usd' : 'vnd',
                },
                {
                    Header: branchID === 11 ? "KHR" : 'USD',
                    accessor: branchID === 11 ? 'vnd' : 'usd',
                },
                {
                    Header: 'YEN',
                    accessor: 'yen',
                })
    
            if (branchID === 11) {
                listRender.push({
                    Header: 'PPCB QR',
                    accessor: 'exp_rate',
                },)
            }
    
            listRender.push(
                {
                    Header: `${card_1 === undefined ? "Card " : card_1}`,
                    accessor: 'vcb',
                },
                {
                    Header: `${card_2 === undefined ? "Card (Company)" : card_2}`,
                    accessor: 'vcb_other',
                },
                {
                    Header: 'ID',
                    accessor: 'ID',
                }
            )
    
            if (type !== "Search" && branchID !== 11) {
                listRender.push(
                    {
                        Header: 'EXP (Name)',
                        accessor: 'exp',
                    },
                    {
                        Header: 'EXP (Price)',
                        accessor: 'exp_rate',
                    },
                )
            }
        } else {
            listRender.push(
                {
                    Header: 'ID',
                    accessor: 'ID',
                }, 
                {
                    Header: 'Hotel Invoice',
                    accessor: 'HI',
                },
                {
                    Header: 'Service Invoice',
                    accessor: 'SI',
                },
                {
                    Header: 'Version',
                    accessor: 'version',
                },
                {
                    Header: 'Name',
                    accessor: 'name',
                },
                {
                    Header: 'Room Number',
                    accessor: 'room',
                },
                {
                    Header: 'Unit Price	',
                    accessor: 'room_rate',
                },
                {
                    Header: 'Check in',
                    accessor: 'checkin',
                },
                {
                    Header: 'Check out',
                    accessor: 'checkout',
                },
                // {
                //     Header: 'Action',
                //     accessor: 'action',
                // },
                )
        }

        return listRender;
    }

   // console.log("data on Rc SUm react", data)

    const columns = React.useMemo(() => checkListRender(), []);
    const updateMyData = (value, itemID, target) => {
        props.onUpdateItem(value, itemID, target);
    }

    const onSelected = (ID) => {
        if (!listDelete.includes(ID)) {
            listDelete.push(ID);
        } else {
            listDelete.splice(listDelete.indexOf(ID), 1);
        }
        props.setListDelete(listDelete);
    }

    const handleDeleteItem = () => {
        props.onSelectDeleteItem(listDelete)
    }

    const handCopyItem = () => {
        props.onCopyItem(listDelete)
    }

    return (
        <Table
            columns={columns}
            data={data}
            key={data}
            edit={edit}
            type={type}
            focusInputName={focusInputName}
            setFocusInputName={setFocusInputName}
            listRoom={listRoom}
            listDelete={listDelete}
            isPrintModel={isPrintModel}
            isReception={isReception}
            branchID={branchID}
            updateMyData={updateMyData}
            onSelected={onSelected}
            handleDeleteItem={handleDeleteItem}
            handCopyItem={handCopyItem}
        />
    )
}

export default RCSumTableItemReactTable
