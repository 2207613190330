import axios from 'axios';
//http://103.130.215.61:5800
export function getUserShareWithBranch(area, token) {
    let newURL = "/user/get_infor_by_area_az_share/" + area;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
};

export const updateUserShareOther = (data, token) => {
    let newURL = "/user/update_other_az_share";
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    })
};
