import { call, put, delay, select } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as receptionApi from '../../../api/reception';
import * as rcSumAction from '../../../constaints/rclistaction';
import { showLoading, hideLoading } from '../../../actions/ui';
import { informationToast, errorToast } from '../../../utils/Toast'

function checkBranchID(id) {
    switch (id) {
        case 2: return 1;
        case 99: return 12;
        case 91: return 15;
        default: return id;
    }
}

export function* fetchSumTable() {
    const state = yield select();
    const dateSelected = state.datepickup.rcsummake;
    const { token, auth } = state;
    const { branchID } = auth.user;
    const day = new Date(dateSelected);
    const lastDay = new Date(dateSelected);
    lastDay.setDate(lastDay.getDate() - 1);

    const lastDayToString = changeDateToString(lastDay, 0);
    const selectedDayToString = changeDateToString(day, 0);
    const lastDayToStringYYYYMMDD = changeDateToString(lastDay, 1);

    yield put(actions.unitsAction.changeContentLoading(""));
    yield put(showLoading());
   // console.log("vo here fetchSumTable")
    // reset Data RC Make SUm
    //yield put(actions.sumTable.resetData());

    const isLoadNewDatabase =  true 
    // Number(branchID) === 15 || Number(branchID) === 3 || Number(branchID) === 8 
    //                             || Number(branchID) === 12 || Number(branchID) === 99 || Number(branchID) === 13 || Number(branchID) === 7 

    var mojaData;
    var debitCheckInData;
    var azDatabaseData;

    try {
        if(isLoadNewDatabase) {
            azDatabaseData = yield call(() => receptionApi.getAzDatabaseData(branchID, lastDayToStringYYYYMMDD, token));
        } else{
            mojaData = yield call(() => receptionApi.getMojaData(checkBranchID(Number(branchID)), lastDayToString, selectedDayToString, token));
        }
      
     //  console.log("azDatabseData", azDatabaseData)
        yield put(actions.unitsAction.changeContentLoading("(1/6) Loading Data from Hotel Invoice MOJA Database"));
        if(isLoadNewDatabase) yield put(actions.unitsAction.changeContentLoading("(1/6) Loading Data from Hotel Invoice Azumaya Database"));
       
        // Check on Debit area RC
        debitCheckInData = yield call(() => receptionApi.importDebitToSum(branchID, changeDateToString(day, 1), token));
        //  debitAccData = yield call(() => importAccDebitToSum(branchID, changeDateToString(day, 1), token));

        var filterDataDebit = debitCheckInData.data;
        // var filterDataAccDebit = debitAccData.data;
    } catch (e) {
        console.log("error: " + e);
    }

    yield put(actions.unitsAction.changeContentLoading("(2/6) Checking Debit Check In"));
    yield put(actions.unitsAction.changeContentLoading(`(3/6) Have ${filterDataDebit.length} Debit Check In`));
    yield put(actions.sumTable.resetData());

    if (filterDataDebit.length > 0) {
        yield put(actions.unitsAction.changeContentLoading("(4/6) Fetch Data Rc Debit Reception Sum"));
        yield put(actions.sumTable.fetchHotelInvoiceFromDebit({ "data": filterDataDebit, "date": dateSelected }));
    }

    // if (filterDataAccDebit.length > 0) {
    //     yield put(actions.unitsAction.changeContentLoading("(5/6) Fetch Acc Debit Data Reception Sum"));
    //     yield put(actions.sumTable.fetchHotelInvoiceFromDebit({ "data": filterDataAccDebit, "date": dateSelected }));
    // }

    yield put(actions.unitsAction.changeContentLoading(`(6/6) Fetch Data Reception Sum`));
   // yield put(actions.sumTable.fetchHotelInvoice({ data: mojaData.data, date: dateSelected }));
   
  //sumTable.getHotelInvoiceAzDatabase
   if(isLoadNewDatabase) yield put(actions.sumTable.getHotelInvoiceAzDatabase({ data: azDatabaseData.data.data, date: dateSelected }));
   else  yield put(actions.sumTable.fetchHotelInvoice({ data: mojaData.data, date: dateSelected }));

   yield put(actions.sumTable.changeEditSumTrue());
   yield put(actions.unitsAction.changeContentLoading("Complete"));

   yield put(hideLoading());

    // reset && call dispatch fetch data.
    if (filterDataDebit.length > 0) {
        informationToast('SUCCESS LOAD DATA FORM MOJA & DEBIT CHECK IN');
    } else {
        if(isLoadNewDatabase) informationToast('SUCCESS LOAD DATA FORM AZUMAYA DATABASE');
        else informationToast('SUCCESS LOAD DATA FORM MOJA');
    }
}

export function* checkAgainMojaData(timeUpdate) {
    const state = yield select();
    const dateSelected = state.datepickup.rcsummake;
    const rcSumData = state.sumtable.content;
    const versionSum = state.sumtable.version;
    const timeUpdateSum = new Date(state.sumtable.updatedAt);
    const { token, auth } = state;
    const { branchID } = auth.user;
    const day = new Date(dateSelected);
    const lastDay = new Date(dateSelected);
    lastDay.setDate(lastDay.getDate() - 1);

    const lastDayToString = changeDateToString(lastDay, 0);
    const selectedDayToString = changeDateToString(day, 0);
    const lastDayToStringYYYYMMDD = changeDateToString(lastDay, 1);
    const selectedToStringYYYYMMDD = changeDateToString(day, 1);

    // Reload Moja data.
    var mojaData ;
    var sumRcWithVersion;
    var azDatabaseResultApi;
    const isLoadNewDatabase = true
    
    //  Number(branchID) === 15 || Number(branchID) === 3 || Number(branchID) === 8 
    //                             || Number(branchID) === 12 || Number(branchID) === 99 || Number(branchID) === 13 || Number(branchID) === 7

    try {
        if(isLoadNewDatabase) {
            azDatabaseResultApi = yield call(() => receptionApi.getCheckAgainAzDatabaseData(
                branchID, 
                selectedToStringYYYYMMDD, 
                {timeUpdate: timeUpdate.payload},
                token
                ));
        } else {
            mojaData = yield call(() => receptionApi.getMojaData(Number(branchID), lastDayToString, selectedDayToString, token));
        }
       // console.log("azDatabaseResultApi", azDatabaseResultApi);
        //   debitData = yield call(() => receptionApi.importDebitToSum(branchID, changeDateToString(day, 1), token));
        if (versionSum > 1) {
            // take time old version;
            sumRcWithVersion = yield call(() => receptionApi.getSumWithVersion(branchID, dateSelected, (versionSum - 1), token));
        }
    } catch (error) {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": `MOJA ${error}`,
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
        //alert(error);
    }

    // Reload Debit data.

    // if(Number(branchID) !== 15 && Number(branchID) !== 3 
    //         && Number(branchID) !== 12 && Number(branchID) !== 8  && Number(branchID) !== 7 && Number(branchID) !== 13
    //             && Number(branchID) !== 99) {
    //     if (mojaData.data.length > 0) { //|| debitData.data.length > 0
    //         if (rcSumData.length > 0) {
    //             const listHI = [];
    //             rcSumData.forEach(item => {
    //                 if (item.HI !== "") {
    //                     listHI.push(item.HI);
    //                 }
    //                 if (item.SI !== "") {
    //                     listHI.push(item.SI);
    //                 }
    //             })
    //             // eslint-disable-next-line array-callback-return
    //             const filterMojaData = mojaData.data.filter((item, index) => {
    //                 let timeHI = new Date(item.created_at);
    //                // let timeSum = new Date(timeUpdateSum);
    
    //                 // If time createdAt === createdAt => new version => need check time Old Version
    //                 if (state.sumtable.updatedAt === state.sumtable.createdAt) {
    //                     if (sumRcWithVersion === undefined) {
    //                         // console.log("vo here 1")
    //                         return (listHI.includes(item.invoice_number.slice(9, 20).trim() + item.printmethod) === false)
    //                     } else {
    //                         // console.log("vo here 2")
    //                         const timeUpdateOldVersion = new Date(sumRcWithVersion.data.updatedAt);
    //                         return (listHI.includes(item.invoice_number.slice(9, 20).trim() + item.printmethod) === false
    //                             && timeUpdateOldVersion < timeHI)
    //                     }
    //                 } else {
    //                     return (listHI.includes(item.invoice_number.slice(9, 20).trim() + item.printmethod) === false
    //                         && timeUpdateSum < timeHI)
    //                 }
    //             });
    
    //             if (filterMojaData.length > 0) yield put(actions.sumTable.fetchHotelInvoice({ "data": filterMojaData, "date": dateSelected }));
    //         } else {
    //             // if have not current Data RC Sum --> add new mojaData`
    //             if (mojaData.data.length > 0) {
    //                 yield put(actions.sumTable.fetchHotelInvoice({ "data": mojaData.data, "date": dateSelected }));
    //             }
    //         }
    //     }
    // } else {
        if (azDatabaseResultApi) {
            yield put(actions.sumTable.getHotelInvoiceAzDatabase({ "data": azDatabaseResultApi.data.data, "date": dateSelected }));
        }
    // }
    informationToast('SUCCESS LOAD DATA');
}

export function* sentReportSumRC() {
    const state = yield select();
    const { token, auth, sumtable, datepickup } = state;
    const { sum_credit, version, content: dataSum, note: noteRcSentMail } = sumtable;
    const dateSelected = datepickup.rcsummake;
    const { branchID } = auth.user;

    // check again this version have data ?
    //receptionApi.getSumWithVersion(branchID, day, version, token)

    try {
        if (version) {
            const checkVersion = yield call(() => receptionApi.getSumWithVersion(branchID, dateSelected, version, token));
            if (checkVersion) {
                if (checkVersion.data) {
                    if (!checkVersion.data.edit) { return errorToast("Can't SEND report, please reload page and check again."); }
                }
            }
        }
    } catch (error) {
        errorToast("Error when send mail")
    }

    //  console.log("check send report");

    const nextDay = new Date(dateSelected);
    nextDay.setDate(nextDay.getDate() + 1);

    yield put(showLoading());
    yield put(actions.unitsAction.changeContentLoading("Updating Data to Server"));

    sumtable["edit"] = false;

    for (let i = 0; i < dataSum.length; i++) {
        var import_to;
        switch (dataSum[i].action) {
            case "DEBIT CHECK IN": {
                import_to = changeDateToString(nextDay, 1)
                break;
            }
            case "DEBIT PAY": {
                import_to = dataSum[i].checkout
                break;
            }
            default: import_to = ""
        }

        const debitRC = {
            "HI": dataSum[i].HI,
            "SI": dataSum[i].SI,
            "name": dataSum[i].name,
            "room": dataSum[i].room,
            "checkin": dataSum[i].checkin,
            "checkout": dataSum[i].checkout,
            "room_rate": dataSum[i].room_rate,
            "sub1": dataSum[i].sub1,
            "sub2": dataSum[i].sub2,
            "sub3": dataSum[i].sub3,
            "sub4": dataSum[i].sub4,
            "sub5": dataSum[i].sub5,
            "name_sub1": dataSum[i].name_sub1,
            "name_sub2": dataSum[i].name_sub2,
            "name_sub3": dataSum[i].name_sub3,
            "name_sub4": dataSum[i].name_sub4,
            "name_sub5": dataSum[i].name_sub5,
            "action": dataSum[i].action,
            "import_to": import_to,
            "paid": 'Debit',
            "sum_credit": sum_credit,
            "version": version,
        }
        yield put(actions.unitsAction.changeContentLoading("Updating Debit"));
        if (rcSumAction.DEBIT_RC_CHECK.includes(dataSum[i].action)) {
            // Upload to RC   
            yield call(() => receptionApi.postDebit(branchID, debitRC, token));
        } else if (rcSumAction.DEBIT_ACC_CHECK.includes(dataSum[i].action)) {
            // UP date to Account Debit
        }
    }

    // if have data on server => Change edit = false , keep same version 
    try {
        if (sumtable._id !== undefined) {
            const updateServer = {
                "edit": false,
                "version": sumtable.version,
                "maker": sumtable.maker,
                "sum_credit": dateSelected,
                "content": sumtable.content
            }
            yield call(() => receptionApi.updateSumAfterEdit(branchID, sumtable._id, updateServer, token))
        } else { // add new day on server`
            const updateServer = {
                "edit": false,
                "version": 1,
                "maker": sumtable.maker,
                "sum_credit": dateSelected,
                "content": sumtable.content
            }
            yield call(() => receptionApi.postSum(branchID, updateServer, token));
        }
    } catch (error) {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": `Update data - ${error}`,
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }

    // --- Sent mail ---- 
    yield put(actions.unitsAction.changeContentLoading(" Sent Mail "));
    try {
        yield call(() => receptionApi.sendMailRCSum(dateSelected,
            noteRcSentMail,
            (version === undefined ? 1 : version),
            token));
        yield put(actions.sumTable.noteSentMail(""));

        const dataInvoiceView = yield call(() => receptionApi.getSum(branchID, dateSelected, token));
        yield put(actions.sumTable.fetchHotelInvoiceEdit(dataInvoiceView.data))
    } catch (error) {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": `${error}`,
            "button1": "",
            "button2": "OK"
        }));
        yield delay(50);
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    }

    yield put(actions.unitsAction.changeContentLoading("Complete sent Sum"));
    yield put(hideLoading());
    informationToast('SUCCESS SENT REPORT DATA');

}

export function* fetchViewTable() {
    const state = yield select();
    const { auth, token, datepickup } = state;
    const branchIDRCView = datepickup.branchIDRCView;
    const dateSelected = datepickup.rcsummake;
    const branchID = branchIDRCView === 0 ? auth.user.branchID : branchIDRCView;

    yield put(actions.sumTable.resetData());

    yield put(showLoading());
    yield put(actions.unitsAction.changeContentLoading("Loading..."));

    yield delay(10);
    yield put(actions.unitsAction.changeContentLoading("Loading Data from Sum Reception"));

    var dataInvoiceView;
    try {
        dataInvoiceView = yield call(() => receptionApi.getSum(branchID, dateSelected, token));
    } catch (error) {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": `MOJA ${error}`,
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
        //alert(error);
    }

    yield delay(10);
    yield put(hideLoading());

    if (dataInvoiceView.data.msg === "editing") {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": (<div>
                <h4>This Report is Editing</h4>
                <h5>* Please check again</h5>
            </div>),
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    } else if (dataInvoiceView.data.msg === "null") {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": (<div>
                <h4>This Report data is empty</h4>
                <h5>* Please check again</h5>
            </div>),
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))
    } else {
        yield put(actions.sumTable.fetchHotelInvoiceEdit(dataInvoiceView.data))
    }
}

export function* onSaveSumRCReport() {
    // Check version => save with this version else save with 1.
    const state = yield select();
    const { token, auth, sumtable, datepickup } = state;
    const { version } = sumtable;
    const { branchID } = auth.user;
    const dateSelect = datepickup.rcsummake;

    try {
        if (version) {
            const checkVersion = yield call(() => receptionApi.getSumWithVersion(branchID, dateSelect, version, token));
            if (checkVersion) {
                if (checkVersion.data) {
                    if (!checkVersion.data.edit) { return errorToast("Can't SAVE report, please reload page and check again."); }
                }
            }
        }
    } catch (error) {
        errorToast("Error when SAVE mail");
    }

    yield put(showLoading());

    try {
        
        if (sumtable._id === undefined) {
            // have not _id => post
            const updateServer = {
                edit: true,
                version: 1,
                maker: sumtable.maker,
                sum_credit: dateSelect,
                content: sumtable.content
            }
            yield call(() => receptionApi.postSum(branchID, updateServer, token));

            // if(updateDate) {
            //     if(updateDate.data) {
            //         window.location.reload();
            //     }
            // }
            // .then((response) =>{
            //     window.location.reload();
            // });
           
        } else {
            yield call(() => receptionApi.updateSumAfterEdit(branchID, sumtable._id, sumtable, token));
        }

        yield delay(100);
        yield put(hideLoading());

        // reload
        try {
            const dataInvoiceView = yield call(() => receptionApi.getSum(branchID, dateSelect, token));
            yield put(actions.sumTable.fetchHotelInvoiceEdit(dataInvoiceView.data))
        } catch (error) {
            yield put(actions.modalAction.modalChange({
                "type": "",
                "title": "NOTIFICATION",
                "content": `${error}`,
                "button1": "",
                "button2": "OK"
            }));
            yield delay(50);
            yield put(actions.modalAction.modalIsOk());
            yield put(actions.modalAction.modalComponent(""))
        }

        informationToast('SUCCESS SAVE DATA');

    } catch (error) {
        console.log("error on post SUm", error)
    }
}

export function* makeSumNewVersion() {
    const state = yield select();
    const { token, auth, sumtable, datepickup } = state;
    const { version } = sumtable;
    const dateSelected = datepickup.rcsummake;
    const { branchID } = auth.user;

    yield put(showLoading());
    try {
        const checkVersion = yield call(() => receptionApi.getSumWithVersion(branchID, dateSelected, version, token));
        const checkVersionNext = yield call(() => receptionApi.getSumWithVersion(branchID, dateSelected, version + 1, token));

        // console.log("check version", checkVersion);

        if (checkVersion) {
            if (checkVersion.data) {
                if (checkVersion.data.edit) {
                    yield put(hideLoading());
                    return errorToast("Can't EDIT report, please reload page and check again.");
                } else {
                    if (checkVersionNext.data.msg !== "null") {
                        yield put(hideLoading());
                        return errorToast("Can't EDIT report, please reload page and check again.");
                    }
                }
            }
        }

        yield call(() => receptionApi.makeNewVersion(branchID, sumtable._id, token))

        // reload
        yield delay(10);
        yield put(actions.unitsAction.changeContentLoading("Reload report"));

        const dataInvoiceView = yield call(() => receptionApi.getSum(branchID, dateSelected, token));
        yield put(actions.sumTable.fetchHotelInvoiceEdit(dataInvoiceView.data))

    } catch (error) {
        yield put(actions.modalAction.modalChange({
            "type": "",
            "title": "NOTIFICATION",
            "content": `Have ${error}`,
            "button1": "",
            "button2": "OK"
        }));
        yield put(actions.modalAction.modalIsOk());
        yield put(actions.modalAction.modalComponent(""))

    }
    yield delay(100);
    yield put(hideLoading());
}

function changeDateToString(date, type) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return (type === 0 ? (dd + '-' + mm + '-' + yyyy) : (yyyy + '-' + mm + '-' + dd));
}