import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faPalette, faCopy, faPlusCircle, } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from 'react-number-format';
import { matchSorter } from 'match-sorter'  // sort, filter

import {
    useTable, useRowSelect, useSortBy, useFilters,
    useGlobalFilter, useAsyncDebounce, usePagination
} from 'react-table'

// --- units - support ---
import { calculateNightsAccSum } from '../../../utils/Date/SupportDate';
import { changeStringVNDtoNumber, changeStringUSDtoNumber } from '../../../utils/Currency/SupportCurrency'
import * as accCheckBoxList from '../../../constaints/AccReport/acccheckbox';

// ---- Components ----
import AccSumTableItemTotal from '../ACCSumTableItemTotal';
import AccSumTotalDaysOnMonth from '../ACCSumTotalDaysOnMonth';
import RCSumDropDownAction from '../../RCSumTable/RCSumDropDownAction';
import AccTotalOnTop from '../ACCTotalOnTop';

import FooterButton from '../../../utils/FooterButton';
import './sumTableItem.css'

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    data,
    accReportEdit,
    accReportType,
    branchID,
    row: { index, values },
    column: { id, Header },
    updateMyData,
    onConfirmChangeStyleRow,
    role
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const itemID = values.ID;
    const onChange = (e, id, target) => {
        if (target === "action") {
            setValue(e);
            if (e === "F.O.C") {
                data[index].nights = 0;
                data[index].unit_price = 0;
                updateMyData(0, itemID, "nights")
            }
            data[index].action = e;
        }
        else setValue(e.target.value)
    };

    const onBlur = () => {
        if (id === "action") {
            updateMyData(data[index].action, itemID, id);
        }
        else updateMyData(value, itemID, id);
    };

    const onCheckInputTelephoneOtherPay = e => {
        if (accReportEdit) {
            updateMyData(value, itemID, id);
        }
        else {
            if (e.target.value > 0) {
                updateMyData("Error->Input text Only", itemID, id);
            } else {
                if (value.toLowerCase().includes("paid")
                    && data[index].style.HI.backgroundColor === "#ad7ae2"
                    && data[index].style.SI.backgroundColor === "#ad7ae2") {
                    data[index].style = {
                        HI: { color: "", backgroundColor: "" }, //color: "red"
                        SI: { color: "", backgroundColor: "" },
                        Service: { color: "", backgroundColor: "" },
                        Discount: { color: "", backgroundColor: "" },
                        Name: { color: "", backgroundColor: "" },
                        Room: { color: "", backgroundColor: "" },
                        CheckIn: { color: "", backgroundColor: "" },
                        CheckOut: { color: "", backgroundColor: "" },
                        UnitPrice: { color: "", backgroundColor: "" },
                        Telephone: { color: "", backgroundColor: "" },
                        // Total: { color: "", backgroundColor: "" } => keep total
                    }
                    updateMyData(data[index].style, itemID, "style");
                }
                updateMyData(value, itemID, id);
            }
        }
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    switch (id) {
        case 'sum_credit': return <input
            value={value}
            disabled={!accReportEdit}
            onChange={onChange}
            onBlur={onBlur} />
        case "version": return <div>
            <div>{value}</div>
            <div
                style={{ color: "blue", cursor: 'pointer' }}
                onClick={() =>
                    window.open(`/acc_make/${Number(branchID)}/${data[index].sum_credit}/${value}`)}
            >(link)</div>
        </div>
        case 'HI': return (accReportType === "month" ?
            //className={data[index] + ''.import_to === undefined ? "" : "op-redcolor"}
            <div
                style={{ color: data[index].style === undefined ? "" : data[index].style.HI.color }}
            > {value}</div >
            : <div className="d-flex">
                <FontAwesomeIcon
                    icon={faPalette}
                    onClick={() => onConfirmChangeStyleRow(data[index])}
                    style={{
                        cursor: 'pointer',
                    }}
                    className={"btn__add_icon" +
                        ((((role === 4 || role === 5) && accReportEdit) || accReportType === "debitModule") ? "" : " op-hidden")
                    } 
                />
                <input
                    // className={data[index].import_to === undefined ? "" : "op-redcolor"}
                    value={value}
                    style={{ color: data[index].style === undefined ? "" : data[index].style.HI.color }}
                    disabled={!accReportEdit || values.SI !== ""}
                    onChange={onChange}
                    onBlur={onBlur} />
            </div>)
        case 'SI': return <input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            style={{ color: data[index].style === undefined ? "" : data[index].style.SI.color }}
            disabled={!accReportEdit || values.HI !== ""} />

        case 'BF': case 'BT': case 'BF & BT': case 'MS 40P': case 'MS 70P': case 'MS 100P':
        case 'MS 70P & BT': case 'MS 40P & BT': case 'MS 100P & BT': case 'HMS 70P': case 'HMS 100P':
            return <input
                style={{ width: (value === undefined ? 50 : (50 + value.length)) + 'px' }}
                value={value}
                disabled={!accReportEdit}
                onChange={onChange}
                onBlur={onBlur} />
        case 'room':
            return <input
                value={value}
                disabled={!accReportEdit}
                onChange={onChange}
                onBlur={onBlur} />
        case 'discount':
            return <NumberFormat
                prefix={'$'}
                type="text"
                disabled={!accReportEdit}
                thousandSeparator={true}
                value={value}
                onChange={onChange}
                onBlur={onBlur} />
        case "rate": return (accReportType === "month" ?
            (data[index].rate === undefined ? "" : value)
            : <input value={value} onChange={onChange} onBlur={onBlur} disabled={Number(branchID) === 11 ? true : !accReportEdit} />)
        case "company": return <div className={(accReportType === "debitModule" ? 'd-flex' : "op-hidden")}>
            <textarea
                style={{ backgroundColor: 'rgb(9, 9, 9, 0.1)', height: '27px' }}
                className={accReportType === "debitModule" ? 'text' : "op-hidden"}
                value={value === undefined ? "" : value}
                disabled={!accReportEdit}
                onChange={onChange}
                onBlur={onBlur} />
        </div>
        case "name": return accReportEdit ?
            <textarea
                style={{
                    fontWeight: '600',
                    width: '97%',
                    color: data[index].style === undefined ? "" : data[index].style.Name.color
                }}
                className={" op-text-left ml-3"}
                value={value === undefined ? "" : value.toUpperCase()}
                disabled={!accReportEdit}
                onChange={onChange}
                onBlur={onBlur}
            /> : <div
                style={{
                    fontWeight: '600',
                    color: data[index].style === undefined ? "" : data[index].style.Name.color
                }}
                className={" op-text-left"}
                onChange={onChange}
                onBlur={onBlur}
            >{value === undefined ? "" : value.toUpperCase()}</div>
        case "paid": return <div className={(accReportType === "debitModule" ? 'text' : "op-hidden")}>{value}</div>
        case "checkin": case "checkout":
            return <input
                type="date"
                value={value}
                disabled={!accReportEdit || data[index].SI !== ""}
                onChange={onChange}
                onBlur={onBlur}
            />
        case "action": return accReportEdit ?
            <RCSumDropDownAction
                type="accMakeSum"
                disabled={!accReportEdit}
                item={data[index]}
                value={value}
                onChangeItem={onChange}
                onBlur={onBlur} />
            : <div>{data[index].action}</div>
        case "laundry": case "minibar": case "transfer": case "dinning": case "other_hotel": case "Other":
            return <div className={" d-flex"} >
                <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    style={{
                        backgroundColor: 'rgb(9, 9, 9, 0.1)',
                        height: '27px',
                        color: data[index].style === undefined ? "" : data[index].style.Telephone.color
                    }}
                    value={value}
                    disabled={(role === 4 || role === 5) ? false : true}
                    onChange={onChange}
                    onBlur={onBlur} />
            </div>
        case "AzOther": case "breakfast": case "sauna": case "massage":
        case "BF": case "vnd": case "usd": case "yen": case "vcb": case "vcb_other": case "exp_rate":
            return <NumberFormat
                type="text"
                disabled={!accReportEdit}
                thousandSeparator={true}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        case "nights": return <NumberFormat
            type="text"
            disabled={!accReportEdit || (data[index].HI === "" ? true : false)}
            thousandSeparator={true}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
        case "unit_price": Number(branchID) === 11 ?
            <NumberFormat
                type="text"
                style={{ minWidth: '60px', }}
                value={(value === "" || value === undefined || value === null) ? "" : value}
                thousandSeparator={true}
                onChange={onChange}
                onBlur={onBlur}
            /> : <NumberFormat
                prefix={'$'}
                type="text"
                disabled={!accReportEdit}
                thousandSeparator={true}
                value={value}
                onChange={onChange}
                onBlur={onBlur} />
        // case "amount": return <div
        //     style={{ minWidth: '60px', }} >
        //     {(value === "" || value === undefined || value === null) ? "" : value.toLocaleString()}
        // </div>

        // eslint-disable-next-line no-fallthrough
        case "amount": return <NumberFormat
            type="text"
            style={{ minWidth: '60px', }}
            value={(value === "" || value === undefined || value === null) ? "" : value}
            thousandSeparator={true}
            onChange={onChange}
            onBlur={onBlur}
        />

        case "sub": case "serviceTotal": return <NumberFormat
            type="text"
            disabled={true}
            thousandSeparator={true}
            value={value}
        />

        case "telephone": case "otherPay": return <div className={" d-flex"} >
            <textarea
                style={{
                    backgroundColor: 'rgb(9, 9, 9, 0.1)',
                    height: '27px',
                    color: data[index].style === undefined ? "" : data[index].style.Telephone.color
                }}
                value={value}
                disabled={(role === 4 || role === 5) ? false : true}
                onChange={onChange}
                onBlur={onCheckInputTelephoneOtherPay} />
        </div>

        default: return accReportType === "month" ? value : <input value={value} onChange={onChange} onBlur={onBlur} disabled={!accReportEdit} />

    }
}

const ViewTableCell = ({
    value: initialValue,
    data,
    branchID,
    onClickOpenHistory,
    accReportType,
    row: { index },
    column: { id },
}) => {
    const [value, setValue] = React.useState(initialValue);
    const findindex = "test";

    switch (id) {
        case "version": return <div>
            <div>{value}</div>
            <div
                style={{ color: "blue", cursor: 'pointer' }}
                onClick={() =>
                    window.open(`/reception_sum/${branchID}/${data[findindex].sum_credit}/${value}`)}
            >(link)</div>
        </div>
        case 'HI': case 'SI': return <div className=""
            onClick={() => onClickOpenHistory(data[index])}>{initialValue}</div>
        case "name": return <div style={{ fontWeight: '600', }} className={" op-text-left ml-3"} > {initialValue} </div>
        case "rate": return (initialValue === undefined ? "" : initialValue)
        case "unit_price": case "sub": case "serviceTotal": case "amount": return <NumberFormat
            type="text"
            disabled={true}
            thousandSeparator={true}
            value={initialValue}
        />
        case "telephone": case "otherPay": return <div className={" d-flex"} >
            <textarea
                style={{
                    backgroundColor: 'rgb(9, 9, 9, 0.1)',
                    height: '27px',
                    color: data[index].style === undefined ? "" : data[index].style.Telephone.color
                }}
                value={initialValue}
                disabled={accReportType === "month"}
            // onChange={onChange}
            //    onBlur={onCheckInputTelephoneOtherPay} 
            />
        </div>
        default: return <div> {initialValue}</div>
    }
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center mb-2">
            <h4 > Search:{' '}</h4>
            <input
                className="ml-2 search_input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Enter here key search"
            />
        </div>
    )
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
}

const defaultColumnMonth = {
    Cell: ViewTableCell,
}

function renderCheckBox(changeChecked, hiddenList) {
    var result = null;
    var keyCheckBox = Object.keys(accCheckBoxList.ACCREPORT_CHECKBOX);

    const onChange = () => {
        changeChecked()
    }

    if (keyCheckBox.length > 0) {
        result = keyCheckBox.map((checkbox, index) => {
            return (<div className="form-check d-flex align-items-center justify-content-center"  >
                <input type="checkbox"
                    className="option ml-2 mr-1"
                    id={"flexCheckChecked" + index}
                    onChange={() => onChange()}
                    //checked={hiddenList.includes(checkbox)}
                    value={checkbox}></input>
                <label 
                    className="form-check-label" 
                    for={"flexCheckChecked" + index}
                    style={{fontSize:'1.3rem'}}
                    > {checkbox.toUpperCase()}  </label>
            </div>)
        })
    }
    return result;
}

// Button 

function Table({ columns, data, updateMyData, skipPageReset,
    handleDeleteItem, handCopyItem, handMoveToDebit,
    onSelected, changeChecked, hidden, accHiddenTab, branchID, role, isBranchHaveNoMassage,
    onClickPaid, onClickHistoryTable, onClickReturnDebit, onClickOpenFilterNote,
    sumTotalAccReportDebit, dayReport, onSaveSumToServer,
    onConfirmChangeStyleRow, onClickOpenHistory, onClickCheckCost, onLoadDataWaitingList,
    updateSumTotal, sumTotal, listCost, listDelete,
    accReportEdit, accReportType,
    sumTotalAccReport, sumTotalAccDebitMonth, hiddenList, listCheckNumberCollect }) {
    // Use the state and functions returned from useTable to build your UI

    React.useEffect(() => {
        setHiddenColumns(hidden)
    }, [hidden]);

    function checkStyle(target, index) {
        switch (target) {
            case "HI": return data[index].style === undefined ? {} : data[index].style.HI;
            case "SI": return data[index].style === undefined ? {} : data[index].style.SI;
            case 'BF': case 'BF Set': case 'BT': case 'BF & BT': case 'BF & BT Set': case 'BF & BT Buffet': case 'MS 40P': case 'MS 70P': case 'MS 100P':
            case 'MS 70P & BT': case 'MS 40P & BT': case 'MS 100P & BT': case 'HMS 70P': case 'HMS 100P':
            case 'BF8': case 'BF6': case 'MS9': case 'MS13_5': case 'FM18': // branchID ===11
            case 'MSR14': case 'MSR19': case 'MSR24': // branchID ===11
            case 'MSV10': case 'MSV15': case 'MSV20': // branchID ===11
            case 'R5': case 'SR12': case 'SR10': // branchID ===11
            case 'BT Fer': case 'BF & BT Fer':  
                return data[index].style === undefined ? {} : data[index].style.Service;
            case 'discount': case 'rate': case 'company': return data[index].style === undefined ? {} : data[index].style.Discount;
            case 'name': return data[index].style === undefined ? {} : data[index].style.Name;
            case 'room': return data[index].style === undefined ? {} : data[index].style.Room;
            case 'checkin': return data[index].style === undefined ? {} : data[index].style.CheckIn;
            case 'checkout': return data[index].style === undefined ? {} : data[index].style.CheckOut;
            case 'action': case 'paid': return data[index].style === undefined ? {} : data[index].style.CheckOut;
            case 'unit_price': return data[index].style === undefined ? {} : data[index].style.UnitPrice;
            case 'serviceTotal': return data[index].style === undefined ? {} : data[index].style.Total;
            case 'vcb':
                return data[index].vcb === "" ? {} : data[index].style === undefined ? {} : data[index].style.Total;
            case 'vcb_usd':
                return data[index].vcb_usd === "" ? {} : data[index].style === undefined ? {} : data[index].style.Total;
            case 'vcb_other':
                return data[index].vcb_other === "" ? {} : data[index].style === undefined ? {} : data[index].style.Total;
            case 'vcb_other_usd':
                return data[index].vcb_other_usd === "" ? {} : data[index].style === undefined ? {} : data[index].style.Total;
            case 'vnd':
                return data[index].vnd === "" ? {} : data[index].style === undefined ? {} : data[index].style.Total;
            case 'usd':
                return data[index].usd === "" ? {} : data[index].style === undefined ? {} : data[index].style.Total;
            case 'yen':
                return data[index].yen === "" ? {} : data[index].style === undefined ? {} : data[index].style.Total;
            default: return {}
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        setHiddenColumns,

        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        // state: {
        //     selectedRowIds,
        //     pageIndex,
        //     pageSize },

    } = useTable({
        columns,
        data,
        defaultColumn: accReportType === "month" ? defaultColumnMonth : defaultColumn,
        updateMyData,
        onSaveSumToServer,
        updateSumTotal,
        changeChecked,
        hiddenList,
        handCopyItem,
        sumTotalAccReportDebit,
        onConfirmChangeStyleRow,
        onClickOpenHistory,
        onClickReturnDebit,
        onClickOpenFilterNote,
        sumTotal,
        branchID,
        dayReport,
        role,
        accReportEdit,
        accReportType,
        listCheckNumberCollect,
        initialState: {
            hiddenColumns: hidden,
        },

    },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            if (accReportType !== "month" && accReportType !== "data10" && accReportType !== "data20" && accReportType !== "debitMonth") {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div className="rp__select" >
                                {/* <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                                onClick={() => onSelected("all")} /> */}
                            </div >
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div className={"rp__select"}>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                      onClick={() => onSelected(row.values.ID)} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
        }
    )

    var accReportTypeTest = accReportType;
    var count = 0;

    function checkTd(value) {
        switch (value) {
            case "sum_credit": return "fix_date_th";
            case "HI": return "fix_HI_th";
            case "SI": return "fix_SI_th";
            case "company": return accReportTypeTest === "debitModule" ? "" : "op-hidden";
            case "paid": return accReportTypeTest === "debitModule" ? "" : " op-hidden";
            default: return ""
        }
    }

    // Render the UI for your table
    return (
        <>
            <div className="d-flex d-flex align-items-center">
                {/* render trash  and copy */}
                <div className={(listDelete.length > 0 && accReportEdit) ? "mr-5 d-flex align-items-center" : "op-hidden"}
                    style={{ cursor: 'pointer' }}
                    onClick={handleDeleteItem}>
                    <FontAwesomeIcon
                        icon={faTrash}
                        className={"btn__add_icon"}
                    />
                    <p className="ml-3" style={{ margin: "auto" }}>Delete</p>
                </div>

                <div className={(listDelete.length === 1 && accReportEdit) ? "mr-5 d-flex align-items-center" : "op-hidden"}
                    style={{ cursor: 'pointer' }}
                    onClick={handCopyItem}>
                    <FontAwesomeIcon
                        icon={faCopy}
                        className="btn__add_icon" />
                    <p className="ml-3" style={{ margin: "auto" }}>Copy</p>
                </div>

                {dayReport === undefined ? "" :
                    <div className={(listDelete.length > 0
                        && accReportType !== "month"
                        && accReportType !== "debitMonth"
                        && ((dayReport.slice(8, 10) === "01") || (dayReport.slice(8, 10) === "02") || (dayReport.slice(8, 10) === "03"))) ?
                        "mr-5 d-flex align-items-center" :
                        "op-hidden"}
                        style={{ cursor: 'pointer' }}
                        onClick={handMoveToDebit}>
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="btn__add_icon" />
                        <p className="ml-3" style={{ margin: "auto" }}>Move to Debit</p>
                    </div>
                }

                {/* render select cost on Day */}
                <div
                    colSpan={visibleColumns.length}
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>

                {/* <div className={Number(branchID) === 11 ? "op-hidden" : "accsum_checkbox d-flex"}> */}
                <div className={"accsum_checkbox d-flex"}>
                     {accReportType !== "month" && accReportType !== "data10" && accReportType !== "data20" && renderCheckBox(changeChecked, accHiddenTab)}
                </div>

                {(accReportType === "month" && accReportType !== "debitModule") ?
                    <div className="ml-5 d-flex justify-content-center" >
                        <button
                            className={accReportType === "debitModule" ? "op-hidden" : "btn btn__footer btn-light d-flex"}
                            onClick={onClickOpenFilterNote}
                        >
                            {/* <FontAwesomeIcon icon={faSave} className="btn__add_icon icon mr-2" /> */}
                            <span className="btn__add_text">FILTER WITH NOTE</span>
                        </button >
                    </div>
                    :
                    <div className={"ml-5 d-flex justify-content-center"} >
                        <button
                            className={ Number(branchID) === 11 ? "op-hidden" 
                                : ((accReportType === "day") ? "btn btn__footer btn-light d-flex" :  "op-hidden")}
                            onClick={onClickCheckCost}
                            style={{ textAlign: "center",alignItems: "center",backgroundColor: "#4caf50",fontWeight: "500" }}
                        >CHECK COST</button >
                        <button
                            className={(accReportType === "day" && dayReport.slice(8, 10) === "01" && accReportEdit) ? "btn btn__footer btn-light d-flex ml-2" : "op-hidden"}
                            onClick={onLoadDataWaitingList}
                            style={{ textAlign: "center",alignItems: "center",backgroundColor: "aqua",fontWeight: "500" }}
                        >LOAD WAITING</button >
                    </div>}
            </div>

            <table className="rp" style={{ marginBottom: '40px' }} {...getTableProps()}>
                {(accReportType === "month" || accReportType === "data10" || accReportType === "data20") ?
                    <AccTotalOnTop
                        hiddenColumns={state.hiddenColumns}
                        isBranchHaveNoMassage={isBranchHaveNoMassage}
                        sumTotalAccReportDebit={sumTotalAccReportDebit}
                        //  sumTotalAccReportDebit={sumTotalAccReportDebit}
                        branchID={branchID}
                        //  Items={sumTotalAccReport}
                        dayReport={dayReport}
                        sumTotalAccReport={accReportType === "month" ? listCost.sumTotalAccReport.dataMonth :
                            accReportType === "data10" ? listCost.sumTotalAccReport.data10 : listCost.sumTotalAccReport.data20}
                    /> : ""}
                <thead style={{
                    position: 'sticky',
                    top: accReportTypeTest === "filterText" ? '' : '42px',
                    zIndex: 11
                }}>
                    {headerGroups.map(headerGroup =>
                    (
                        <tr className="rp_row-fix" {...headerGroup.getHeaderGroupProps()}>

                            {headerGroup.headers.map(column => (
                                <th
                                    // className={"rp__" + column.id}
                                    className={column.classStyle ? column.classStyle : ""}
                                    {...column.getHeaderProps((column.id === "HI" || column.id === "SI" || column.id === "sum_credit") ? column.getSortByToggleProps() : "")}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>

                                </th>
                            ))}
                        </tr>
                    )
                    )}
                </thead>

                <tbody {...getTableBodyProps()}>

                    {rows.map((row, i) => {
                        prepareRow(row)
                        // console.log("re-render")
                        return (
                            <>
                                <tr
                                    // className={rcSumAction.DEBIT_LINE_COLOR.includes(row.values.action) ? " bg_yellow" : ""}
                                    {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.column.id !== "ID") {
                                            return (
                                                <td
                                                    style={checkStyle(cell.column.id, i)}
                                                    className={"input__" + cell.column.id + " " + checkTd(cell.column.id)}
                                                    {...cell.getCellProps()}>
                                                    {cell.render('Cell')}

                                                    {(cell.column.Header === "Paid") ? cell.value === "Debit"
                                                        ? (<h4
                                                            style={{
                                                                fontSize: '1.1rem',
                                                                color: 'red',
                                                                cursor: 'pointer'
                                                            }}
                                                            className="ml-2"
                                                            onClick={() => onClickPaid(row.original)} >
                                                            (Action ?)
                                                        </h4>)
                                                        : (<h4
                                                            style={{
                                                                fontSize: '1.1rem',
                                                                color: 'red',
                                                                cursor: 'pointer'
                                                            }}
                                                            className="ml-2"
                                                            onClick={() => onClickReturnDebit(row.original)} >
                                                            (Action ?)
                                                        </h4>)
                                                        : ""}
                                                    {(cell.column.Header === "Action") ? data[i].log === undefined ? "" :
                                                        data[i].log.length > 0 ?
                                                            <h4
                                                                style={{
                                                                    fontSize: '1.1rem',
                                                                    color: 'blue',
                                                                    cursor: 'pointer'
                                                                }}
                                                                className={accReportTypeTest === "debitModule" ? "ml-2" : "op-hidden"}
                                                                onClick={() => onClickHistoryTable(row.original)} >
                                                                (history)
                                                            </h4> : "" : ""}
                                                </td>)
                                        }
                                    })}
                                </tr>

                                {
                                    (accReportTypeTest === "month" || accReportTypeTest === "data10"
                                        || accReportTypeTest === "data20") ? (i + 1) === listCheckNumberCollect[count] ?
                                        <AccSumTotalDaysOnMonth
                                            branchID={branchID}
                                            count={count = count + 1}
                                            isBranchHaveNoMassage={isBranchHaveNoMassage}
                                            Items={listCost.listCost[data[i].sum_credit] === undefined ? [] :
                                                listCost.listCost[data[i].sum_credit].collection}
                                            BG="bg_pink_lotus_color"
                                            hiddenColumns={state.hiddenColumns} /> : ""
                                        : ""
                                }
                            </>
                        )
                    })}
                    <br />
                    <AccSumTableItemTotal
                        accReportType={accReportType}
                        branchID={branchID}
                        listHidden={hidden}
                        isBranchHaveNoMassage={isBranchHaveNoMassage}
                        Items={accReportType === "month" ? listCost.sumTotalAccReport.dataMonth :
                            accReportType === "data10" ? listCost.sumTotalAccReport.data10 :
                                accReportType === "data20" ? listCost.sumTotalAccReport.data20 :
                                    (accReportType === "debitMonth" ? sumTotalAccReportDebit : sumTotal)} //sumTotalAccReport 
                        hiddenColumns={state.hiddenColumns}
                        updateSumTotal={updateSumTotal} />
                </tbody>
            </table>
        </>
    )
}

function AccSumTableItems(props) {
    const { sumTotalAccReportDebit, sumOnDay, accReportType, Items, debitList,
        hiddenList, listDelete, dayReport, sum_credit, delay,} = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const dataBranchOnState = useSelector(state => state.datepickup.selectAccSum);
    const user = useSelector(state => state.auth.user);
    let { role, accHiddenTab } = user;
    const branchID = dataBranchOnState.ID === 0 ? props.branchID : dataBranchOnState.ID;
    var [data, setData] = React.useState([Items]);
    var [data2, setData2] = React.useState([]);
    const [value, setValue] = React.useState([]);
    var countManipulation = useRef(0);
    //  var data2 = [];
    var filterByDebit = [];
    var filterByOtherDebit = [];

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const sumTotal = accReportType === "filterText" ? props.sumTotal : useSelector(state => state.accsumtotal);

    const accReportEdit = sumOnDay.edit;
    const listCost = sumOnDay.listCost;
    const listCheckNumberCollect = sumOnDay.listCheckNumberCollect
    const isBranchHaveNoMassage = false; // CONSTANTS.BRANCH_HAVE_NOTE_MASSAGE.includes(Number(branchID));

    useEffect(() => {
        switch (accReportType) {
            case "month": case "data10": case "data20":
                checkSumTotalReport();
                delay(2);
                setData(Items)
                // eslint-disable-next-line react-hooks/exhaustive-deps
                if (debitList === undefined || debitList.length === 0) {
                    setData2([])
                } else setData2(debitList);

                break;
            case "debitModule":
                const filterWithDebit = async () => {
                    if (Items !== undefined) {
                        // var sumAccDebit = [];
                        // var sumAccDebitPaid = [];
                        await Items.accDebit.forEach(debit => {
                            if (debit.paid !== "Paid") {
                                filterByDebit.push(debit);
                            } else {
                                filterByOtherDebit.push(debit);
                            }
                        });
                    }
                }
                filterWithDebit();
                setData(filterByDebit);
                setData2(filterByOtherDebit);
                break;
            case "searchAdvance": case "filterText":
                setData(Items)
                data2 = [];
                break;
            case "nothing":
                setData(Items);
                break;
            case "waiting":
                setData(Items)
                break;
            default:
                updateSumTotal(Items);
                setData(Items)
                data2 = []
                break;
        }
    }, [Items, Items.length, branchID]);

    useEffect(() => {
        checkColumns();
        checkHiddenColum()
    }, [branchID]);

    function checkHiddenColum() {
        if(accHiddenTab) {
            let hiddenColumn = [];
            var listHidden = accCheckBoxList.ACCREPORT_CHECKBOX;
            hiddenColumn = ['ID'];
           
            let aInputs = document.getElementsByClassName("option");
            for (var i = 0; i < aInputs.length; i++) {
                if (accHiddenTab.includes(aInputs[i].defaultValue)) {
                    hiddenColumn = hiddenColumn.concat(listHidden[aInputs[i].defaultValue]);
                    aInputs[i].checked = true;
                }
            }
            setValue(hiddenColumn)
        }
    }

    var sumTotalAccReport = {
        sum_nights: 0,
        sum_amount: 0,
        sum_telephone: 0,
        sum_laundry: 0,
        sum_freeLD: 0, //special branchID =11
        sum_minibar: 0,
        sum_transfer: 0,
        sum_other_hotel: 0,
        sum_dinning: 0,
        sum_cafe: 0, //special branchID =11
        sum_AzOther: 0,
        sum_sub: 0,
        sum_breakfast: 0,
        sum_sauna: 0,
        sum_paiddebit: 0, //special branchID =11
        sum_massage: 0,
        sum_serviceTotal: 0,
        sum_vnd: 0,
        sum_usd: 0,
        sum_yen: 0,
        sum_vcb: 0,
        sum_vcb_other: 0,
        sum_vcb_other_usd: 0,
        sum_vcb_usd: 0,
        sum_exp_rate: 0, //special branchID =11
        sum_otherPay: 0,
    };

    var sumTotalAccDebitMonth = {
        sum_nights: 0,
        sum_amount: 0,
        sum_sub: 0,
        sum_serviceTotal: 0,
        sum_telephone: 0,
        sum_laundry: 0,
        sum_minibar: 0,
        sum_transfer: 0,
        sum_other_hotel: 0,
        sum_dinning: 0,
        sum_AzOther: 0,
        sum_breakfast: 0,
        sum_sauna: 0,
        sum_massage: 0,
        sum_vnd: 0,
        sum_usd: 0,
        sum_yen: 0,
        sum_vcb: 0,
        sum_vcb_other: 0,
        sum_vcb_other_usd: 0,
        sum_vcb_usd: 0,
        sum_otherPay: 0,
        sum_freeLD: 0, //special branchID =11
        sum_cafe: 0, //special branchID =11
        sum_paiddebit: 0, //special branchID =11
        sum_exp_rate: 0, //special branchID =11
    };

    function checkShowBillColumns() {
        const showBillColumns = [];

        showBillColumns.push(
            {
                Header: 'Hotel Invoice',
                accessor: 'HI', // accessor is the "key" in the data
                classStyle: 'rp__size-l bg_orange_blur_color fix_HI',
            },
            {
                Header: 'Service Invoice',
                accessor: 'SI',
                classStyle: 'rp__size-m bg_orange_blur_color fix_SI',
            },
            {
                Header: 'BF',
                accessor: 'BF',
                classStyle: 'rp__size-xs bg_orange_blur_color',
            },
        )

        if(Number(branchID) !== 1 && Number(branchID) !== 4 && Number(branchID) !== 9 && Number(branchID) !== 11) {
            showBillColumns.push(
                {
                    Header: 'BF Set',
                    accessor: 'BF Set',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
            )
        }

        showBillColumns.push(
            {
                Header: 'BT',
                accessor: 'BT',
                classStyle: 'rp__size-xs bg_orange_blur_color',
            },
            {
                Header: 'BF & BT',
                accessor: 'BF & BT',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },

        )

        // if(Number(branchID) === 11 || Number(branchID) === 4 || Number(branchID) === 9) {
        //     showBillColumns.push(
        //         {
        //             Header: 'BF & BT',
        //             accessor: 'BF & BT',
        //             classStyle: 'rp__size-xs bg_orange_blur_color'
        //         },
        //     )
        // } 
        // else {
        //     showBillColumns.push(
        //         {
        //             Header: 'BF & BT Set',
        //             accessor: 'BF & BT Set',
        //             classStyle: 'rp__size-xs bg_orange_blur_color'
        //         },
        //     )
        // }

        if(Number(branchID) === 13) {
            showBillColumns.push(
                {
                    Header: 'BF & BT Buffet',
                    accessor: 'BF & BT Buffet',    
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
            )
        }

        if(Number(branchID) === 8) {
            showBillColumns.push(
                {
                    Header: 'BT Fer',
                    accessor: 'BT Fer',
                    classStyle: 'rp__size-m bg_orange_blur_color',
                },
                {
                    Header: 'BF & BT Fer',
                    accessor: 'BF & BT Fer',
                    classStyle: 'rp__size-m bg_orange_blur_color',
                },
            )
        }

        // if (!isBranchHaveNoMassage) {
        if (Number(branchID) !== 11) {
            showBillColumns.push(
                {
                    Header: 'MS 40P',
                    accessor: 'MS 40P',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS 70P',
                    accessor: 'MS 70P',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS 100P',
                    accessor: 'MS 100P',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
            )

            if (Number(branchID) === 1 || Number(branchID) === 12) {
                showBillColumns.push(
                    {
                        Header: 'MS 40P & BT',
                        accessor: 'MS 40P & BT',
                        classStyle: 'rp__size-xs bg_orange_blur_color'
                    })
            }

            showBillColumns.push(
                {
                    Header: 'MS 70P & BT',
                    accessor: 'MS 70P & BT',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS 100P & BT',
                    accessor: 'MS 100P & BT',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'HMS 70P',
                    accessor: 'HMS 70P',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'HMS 100P',
                    accessor: 'HMS 100P',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                })
        } else {
            // special colum with AZPP
            showBillColumns.push(
                {
                    Header: 'BF 6$',
                    accessor: 'BF6',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'BF 8$',
                    accessor: 'BF8',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS 9$',
                    accessor: 'MS9',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS 13.5$',
                    accessor: 'MS13_5',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'FM 18$',
                    accessor: 'FM18',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS + Rot 18$',
                    accessor: 'MSR14',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS + Rot 20$',
                    accessor: 'MSR19',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS + Rot 25$',
                    accessor: 'MSR24',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS VIS 10$',
                    accessor: 'MSV10',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS VIS 15$',
                    accessor: 'MSV15',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'MS VIS 20$',
                    accessor: 'MSV20',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'Roten 8$',
                    accessor: 'R5',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'JP set + Rot 14$',
                    accessor: 'SR12',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
                {
                    Header: 'Other set + Rot 12$',
                    accessor: 'SR10',
                    classStyle: 'rp__size-xs bg_orange_blur_color'
                },
            )
        }

        // }
        return showBillColumns;
    };

    function checkColumnsAZUMAYA() {
        const columnsAzumaya = [];

        columnsAzumaya.push(
            {
                Header: 'Unit price',
                accessor: 'unit_price',
                classStyle: 'rp__size-m bg_prime_blur_color'
            },
            {
                Header: 'Nights',
                accessor: 'nights',
                classStyle: 'rp__size-xs bg_prime_blur_color'
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                classStyle: 'rp__size-l bg_prime_blur_color'
            },
            // {
            //     Header: Number(branchID) === 11 ? "PickUp/RentalCar" : 'Telephone/Note',
            //     accessor: Number(branchID) === 11 ? 'transfer' : 'telephone',
            //     classStyle: 'rp__size-xl bg_prime_blur_color'
            // },
            {
                Header: 'Telephone/Note',
                accessor: 'telephone',
                classStyle: 'rp__size-xl bg_prime_blur_color'
            }
        )

        if (Number(branchID) === 11) {
            columnsAzumaya.push(
                {
                    Header: "PickUp/RentalCar",
                    accessor: 'transfer',
                    classStyle: 'rp__size-xl bg_prime_blur_color'
                }
            )
        }
        columnsAzumaya.push(
            {
                Header: 'Laundry',
                accessor: 'laundry',
                classStyle: 'rp__size-s bg_prime_blur_color'
            },
            {
                Header: Number(branchID) === 11 ? "Free L/D" : 'Minibar',
                accessor: Number(branchID) === 11 ? "freeLD" : 'minibar',
                classStyle: 'rp__size-s bg_prime_blur_color'
            },
            {
                Header: Number(branchID) === 11 ? 'Minibar' : 'Pickup/ Transfer or Tour',
                accessor: Number(branchID) === 11 ? 'minibar' : 'transfer',
                classStyle: 'rp__size-s bg_prime_blur_color'
            },
        )


        if (Number(branchID) !== 11) {
            columnsAzumaya.push(
                {
                    Header: 'Other Hotel',
                    accessor: 'other_hotel',
                    classStyle: 'rp__size-s bg_prime_blur_color'
                },
            )
        }

        columnsAzumaya.push(
            {
                Header: Number(branchID) === 11 ? "Massage" : 'Lunch / Dinning',
                accessor: Number(branchID) === 11 ? "massage" : 'dinning',
                classStyle: 'rp__size-s bg_prime_blur_color'
            },
            {
                Header: 'Other',
                accessor: 'AzOther',
                classStyle: 'rp__size-s bg_prime_blur_color'
            },
            {
                Header: 'Sub',
                accessor: 'sub',
                classStyle: 'rp__size-l bg_prime_blur_color'
            },
        )

        return columnsAzumaya;
    }

    function checkColumnsService() {
        const columnsService = [];
        if (Number(branchID) === 11) {
            columnsService.push(
                {
                    Header: 'Restaurant',
                    accessor: 'dinning',
                    classStyle: 'rp__size-s'

                },
                {
                    Header: 'Cafe',
                    accessor: 'cafe',
                    classStyle: 'rp__size-s'

                },
                {
                    Header: 'BREAKFAST',
                    accessor: 'breakfast',
                    classStyle: 'rp__size-s'

                },
                {
                    Header: 'SAUNA',
                    accessor: 'sauna',
                    classStyle: 'rp__size-s'

                },
                {
                    Header: 'Paid debit',
                    accessor: 'paiddebit',
                    classStyle: 'rp__size-s'

                },
                {
                    Header: 'TOTAL',
                    accessor: 'serviceTotal',
                    classStyle: 'rp__size-l'
                },
            )
        } else {
            columnsService.push({
                Header: 'BREAKFAST',
                accessor: 'breakfast',
                classStyle: 'rp__size-s'
            },
                {
                    Header: 'SAUNA',
                    accessor: 'sauna',
                    classStyle: 'rp__size-s'

                },
                {
                    Header: 'MASSAGE',
                    accessor: 'massage',
                    classStyle: 'rp__size-s'

                },
                {
                    Header: 'TOTAL',
                    accessor: 'serviceTotal',
                    classStyle: 'rp__size-l'
                },)
        }
        return columnsService;
    }

    function checkColumns() {
        const checkColumns = [];
        if (accReportType === "searchAdvance") {
            checkColumns.push(
                {
                    Header: 'Date',
                    accessor: 'sum_credit', // accessor is the "key" in the data
                    enableRowSpan: true,
                    classStyle: 'rp__size-l hidden-border w-80 fix_date'
                },
                {
                    Header: 'Version',
                    accessor: 'version', // accessor is the "key" in the data
                    enableRowSpan: true,
                    classStyle: 'rp__size-xs hidden-border w-80 fix_date'
                },
                {
                    Header: 'Bill',
                    accessor: 'Bill',
                    classStyle: 'bg_orange_color',
                    columns: checkShowBillColumns()
                },);
        } else {
            checkColumns.push(
                {
                    Header: 'Date',
                    accessor: 'sum_credit', // accessor is the "key" in the data
                    enableRowSpan: true,
                    classStyle: 'rp__size-l hidden-border w-80 fix_date'
                },
                {
                    Header: 'Bill',
                    accessor: 'Bill',
                    classStyle: 'bg_orange_color',
                    columns: checkShowBillColumns()
                },);
        }


        if (Number(branchID) !== 11) {
            checkColumns.push(
                {
                    Header: Number(branchID) === 11 ? "DEBIT/PAID" : 'Discount',
                    accessor: 'discount',
                    classStyle: Number(branchID) === 11 ? 'rp__size-s' : 'rp__size-l',
                },
                {
                    Header: 'Rate',
                    accessor: 'rate',
                    classStyle: 'rp__size-xs',
                },
            )
        }

        checkColumns.push({
            Header: 'Company',
            classStyle: (accReportType === "debitModule" ? '' : "op-hidden"),
            columns: [
                {
                    Header: 'Company',
                    accessor: 'company',
                    classStyle: (accReportType === "debitModule" ? '' : "op-hidden") + " rp__size-xl",
                }
            ]
        },
            {
                Header: 'Description',
                accessor: 'name',
                classStyle: 'rp__size-xxl',
            },
            {
                Header: 'Room Number',
                accessor: 'room',
                classStyle: 'rp__size-s'
            },
            {
                Header: 'Date',
                columns: [
                    {
                        Header: 'Check in',
                        accessor: 'checkin',
                        classStyle: 'rp__size-l'
                    },
                    {
                        Header: 'Check out',
                        accessor: 'checkout',
                        classStyle: 'rp__size-l'
                    },
                ]
            },
            {
                Header: 'Other',
                classStyle: 'bg_orange_color',
                columns: accReportType !== "debitModule" ? [
                    {
                        Header: 'Action',
                        accessor: 'action',
                        classStyle: 'rp__size-xl bg_orange_blur_color'
                    },] :
                    [
                        {
                            Header: 'Action',
                            accessor: 'action',
                            classStyle: 'rp__size-xl bg_orange_blur_color'
                        },
                        {
                            Header: 'Paid',
                            accessor: 'paid',
                            classStyle: (accReportType === "debitModule" ? '' : "op-hidden") + ' rp__size-m bg_orange_blur_color'
                        },
                    ]
            },
            {
                Header: Number(branchID) === 11 ? "ACCOMMODATION CHARGE" : 'AZUMAYA',
                classStyle: 'bg_prime_color',
                columns: checkColumnsAZUMAYA()
            },
            {
                Header: 'Service',
                columns: checkColumnsService()
            },
            {
                Header: 'Guest Pay by Cash',
                classStyle: 'bg_ocean_color',
                columns: [
                    {
                        Header: Number(branchID) === 11 ? 'USD' : 'VND',
                        accessor: Number(branchID) === 11 ? 'usd' : 'vnd',
                        classStyle: 'rp__size-l bg_ocean_blur_color'
                    },
                    {
                        Header: Number(branchID) === 11 ? 'KHR' : 'USD',
                        accessor: Number(branchID) === 11 ? 'vnd' : 'usd',
                        classStyle: 'rp__size-s bg_ocean_blur_color'
                    },
                    {
                        Header: 'JPY',
                        accessor: 'yen',
                        classStyle: 'rp__size-s bg_ocean_blur_color'
                    },
                ]
            },
            {
                Header: 'Transfer',
                classStyle: 'bg_ocean_color',
                columns: [
                    {
                        Header: Number(branchID) === 11 ? 'CAMPU\nUSD' : 'VND',
                        accessor: Number(branchID) === 11 ? 'vcb' : 'vcb_other',
                        classStyle: Number(branchID) === 11 ? 'rp__size-s bg_ocean_blur_color' : 'rp__size-l bg_ocean_blur_color'
                    },
                    {
                        Header: Number(branchID) === 11 ? 'ACLEDA USD' : 'USD',
                        accessor: Number(branchID) === 11 ? 'vcb_other' : 'vcb_other_usd',
                        classStyle: 'rp__size-s bg_ocean_blur_color'
                    },
                ],
            },
            {
                Header: 'Guest Pay by Card',
                classStyle: 'bg_ocean_color',
                columns: [
                    {
                        Header: Number(branchID) === 11 ? 'PPCB QR' : 'VND',
                        accessor: Number(branchID) === 11 ? 'exp_rate' : 'vcb',
                        classStyle: Number(branchID) === 11 ? 'rp__size-s bg_ocean_blur_color' : 'rp__size-l bg_ocean_blur_color'
                    },
                    {
                        Header: Number(branchID) === 11 ? 'ABA USD' : 'USD',
                        accessor: 'vcb_usd',
                        classStyle: 'rp__size-s bg_ocean_blur_color'
                    },
                    {
                        Header: Number(branchID) === 11 ? 'CARD TYPE' : 'Note Pay',
                        accessor: 'otherPay',
                        classStyle: 'rp__size-m bg_ocean_blur_color'

                    },
                ],
            },
            {
                Header: 'ID',
                classStyle: 'op-hidden',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'ID',
                        classStyle: 'op-hidden'
                    },
                ]
            })
        return checkColumns;
    }

    const columns = React.useMemo(
        () => checkColumns(), [branchID]);

    const onClickPaid = (debit) => {
        props.onConfirmChangeHavePaid(debit)
    }

    const onClickHistoryTable = (debit) => {
        props.onClickOpenHistory(debit)
    }

    const onConfirmSave = () => {
        countManipulation.current = 0;
        props.onConfirmSave(data, accReportType);
    }

    const onClickOpenFilterNote = (debit) => {
        props.onClickOpenFilterNote(debit)
    }

    const onClickReturnDebit = (debit) => {
        props.onConfirmChangeDebit(debit)
    }

    const onClickCheckCost = () => {
        props.onClickCheckCost()
    }

    const onLoadDataWaitingList = () => {
        props.onLoadDataWaitingList()
    }

    const checkSumTotalReport = () => {
        if (accReportType === "month" && listCost !== undefined) {
            // filter list day not edit

            if (debitList.length > 0) {
                debitList.forEach(debit => {
                    sumTotalAccDebitMonth.sum_nights += debit.nights;
                    sumTotalAccDebitMonth.sum_amount += debit.amount;
                });

                sumTotalAccDebitMonth.sum_sub = sumTotalAccDebitMonth.sum_amount;
                sumTotalAccDebitMonth.sum_serviceTotal = sumTotalAccDebitMonth.sum_amount;
            }
        }
    };

    const updateMyData = useCallback((value, itemID, target) => {
        //data
        const newData = [...data];
        const findIndex = newData.findIndex(({ ID }) => ID === itemID);
        newData[findIndex][target] = value;
        if (target === "action") {
            setData(newData);
        };

        // props.onUpdateItem(value, itemID, target);
        const LIST_CHECK_CHANGE_SUB = ["HI", "SI", "unit_price", "nights", "discount", "rate", "checkin", "checkout", "telephone",
            "laundry", "minibar", "transfer", Number(branchID) === 11 ? "massage" : "dinning", "AzOther", "other_hotel"];

        const LIST_CALCULATE_SUB = Number(branchID) === 11
            ? ["amount", "telephone", "transfer", "laundry", "freeLD", "minibar", "massage", "AzOther"]
            : ["amount", "telephone", "laundry", "minibar", "transfer", "other_hotel", "dinning", "AzOther"];

        const LIST_CHECK_CHANGE_TOTAL = Number(branchID) === 11
            ? ["sub", "dinning", "cafe", "breakfast", "sauna", "paiddebit"]
            : ["sub", "breakfast", "sauna", "massage"];

        // const LIST_CHECK_PAY = ["vnd", "usd", "yen", "vcb", "vcb_other", "vcb_other_usd", "vcb_usd"];
        const LIST_NOTE = ["telephone", "otherPay", "laundry", "minibar", "transfer", "dinning", "AzOther"];
        var { checkin, checkout, HI, SI } = newData[findIndex];

        function calculateSumOnList(list) {
            var sumCalculate = 0;
            list.forEach(item => {
                if (item === "telephone") {
                    sumCalculate += newData[findIndex]["telephone"] > 0 ? changeStringVNDtoNumber(newData[findIndex]["telephone"]) : 0
                } else sumCalculate += changeStringVNDtoNumber(newData[findIndex]["" + item]);
            })
            return sumCalculate;
        }

        // check night
        // eslint-disable-next-line no-mixed-operators
        if ((target === "HI" && value === "") || (newData[findIndex].action.toLowerCase().includes("debit"))
            // eslint-disable-next-line no-mixed-operators
            && accReportType !== "debitModule" && accReportType !== "debitOnSum") {
            newData[findIndex].nights = 0
        } else if (checkin !== "" && checkout !== "" && HI !== "" && target !== "nights" & (target === "checkin" || target === "checkout")) {
            newData[findIndex].nights = calculateNightsAccSum(checkin, checkout, sum_credit);
            if (newData[findIndex].nights === "") newData[findIndex].checkout = "";
        }

        if (LIST_CHECK_CHANGE_SUB.includes(target)) {
            const roomRate = changeStringUSDtoNumber(newData[findIndex].discount);

            if (target === "checkin" && newData[findIndex].style.HI.backgroundColor !== "#ad7ae2") {
                if (checkout !== "") newData[findIndex].nights = calculateNightsAccSum(value, checkout, sum_credit);
            }
            if (target === "checkout" && newData[findIndex].style.HI.backgroundColor !== "#ad7ae2") {
                if (checkin !== "") newData[findIndex].nights = calculateNightsAccSum(checkin, value, sum_credit);
            }

            const nights = (target === "nights" ? value : newData[findIndex].nights);

            const priceOneNight = target === "unit_price" ? changeStringVNDtoNumber(value) :
                (target !== "rate" && target !== "discount") ? newData[findIndex]["unit_price"] :
                    (newData[findIndex].HI !== "" && roomRate !== "" && roomRate !== 0 && roomRate !== undefined) ?
                        Number(Math.round(Number(roomRate * 100 * 1000) / 100 * newData[findIndex].rate)) : "";

            newData[findIndex]["unit_price"] = priceOneNight;

            if (target === "rate" || target === "discount" || target === "nights" || target === "unit_price" || target === "checkin" || target == "checkout") {
                newData[findIndex]["amount"] = ((priceOneNight === "" || (accReportType !== "debitOnSum" && newData[findIndex].action.includes("DEBIT")))
                    && accReportType !== "debitOnSum")
                    ? "" :
                    Number(branchID) === 11 ? Math.round(priceOneNight * nights * 100) / 100
                        : Math.round(priceOneNight * nights);
            }

            newData[findIndex]["sub"] = ((HI === "" && SI === "") ? 0 : calculateSumOnList(LIST_CALCULATE_SUB));
            newData[findIndex]["serviceTotal"] = calculateSumOnList(LIST_CHECK_CHANGE_TOTAL);
            setData(newData);
        }

        if (LIST_CHECK_CHANGE_TOTAL.includes(target)) {
            newData[findIndex]["serviceTotal"] = calculateSumOnList(LIST_CHECK_CHANGE_TOTAL);
            setData(newData);
        }

        if (target === "amount") {
            newData[findIndex]["sub"] = ((HI === "" && SI === "") ? 0 : calculateSumOnList(LIST_CALCULATE_SUB));
            newData[findIndex]["serviceTotal"] = calculateSumOnList(LIST_CHECK_CHANGE_TOTAL);
            setData(newData);
        }

        if (LIST_NOTE.includes(target) && !accReportEdit) {
            // updatge to server
            onUpdateNoteToServer(value, itemID, target);
        }

        if (target === "company") {
            setData(newData);
        } else {
            updateSumTotal(newData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        countManipulation.current = countManipulation.current + 1;

        if (countManipulation.current === 10) {
            countManipulation.current = 0;
            props.onSaveSum();
        }
     //   console.log("countManipulation: ", countManipulation)
    }, [data]);

    const updateSumTotal = (sumtotal) => {
        if (accReportType !== "searchAdvance") props.sumTotalUpdate(sumtotal)
    }

    const onUpdateNoteToServer = (value, ID, target) => {
        props.onSaveSumToServer(value, ID, target);
    }

    var s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(18).substring(1);
    }

    var generateID = () => {
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4()
            + '-' + s4() + s4() + s4()
    }

    const changeChecked = () => {
        var aInputs = document.getElementsByClassName("option");
        var hiddenColumn = [];
        const listSaveOnSever = [];
        var listHidden = accCheckBoxList.ACCREPORT_CHECKBOX;
        //accHiddenTab
        hiddenColumn = ['ID'];

        for (var i = 0; i < aInputs.length; i++) {
            if (aInputs[i].checked) {
                hiddenColumn = hiddenColumn.concat(listHidden[aInputs[i].defaultValue]);
                listSaveOnSever.push(aInputs[i].defaultValue)
            }
        }
        props.onSaveAccHiddenTab(listSaveOnSever);
        setValue(hiddenColumn);
    }

    const onSelected = (ID) => {
        if (!listDelete.includes(ID)) {
            listDelete.push(ID);
        } else {
            listDelete.splice(listDelete.indexOf(ID), 1);
        }
        props.setListDelete(listDelete);
    }

    const handleDeleteItem = () => {
        props.onSelectDeleteItem(listDelete)
    }

    const handCopyItem = () => {

        const newList = [...data]
        const findIndex = data.findIndex(({ ID }) => ID === listDelete[0]);
        newList.push({
            ...data[findIndex],
            ID: generateID()
        })
        setData(newList)
        props.onCopyItem(listDelete)
    }

    const handMoveToDebit = () => {
        // reset select checkbox
        const newList = [...data]
        setData(newList);
        props.onSelectMoveDebitItem(listDelete)
    }

    const addNewTable = () => {
        const newData = [...data];

        var table = {
            ID: generateID(),
            "discount": "",
            "AzOther": "",
            "HI": "",
            "SI": "",
            "BF": "",
            "BT": "",
            "BF & BT": "",
            "MS 40P": "",
            "MS 70P": "",
            "MS 100P": "",
            "MS 70P & BT": "",
            "MS 100P & BT": "",
            "HMS 70P": "",
            "HMS 100P": "",
            "MS 40P & BT": "",
            "action": "",
            "amount": "",
            "breakfast": "",
            "checkin": "",
            "checkout": "",
            "error_code": "",
            "exp": "",
            "exp_rate": "",
            "exp_rate_type": "",
            "laundry": "",
            "dinning": "",
            "massage": "",
            "minibar": "",
            "other_hotel": "",
            "company": "",
            "name": "",
            "name_sub1": "",
            "name_sub2": "",
            "name_sub3": "",
            "name_sub4": "",
            "name_sub5": "",
            "nights": 0,
            "unit_price": "",
            "otherPay": "",
            "others": "",
            "room": "",
            "room_rate": "",
            "sauna": "",
            "serviceTotal": "",
            "sub": "",
            "sub1": false,
            "sub2": false,
            "sub3": false,
            "sub4": false,
            "sub5": false,
            "telephone": "",
            "textName": "",
            "transfer": "",
            "usd": "",
            "vcb": "",
            "vcb_other": "",
            "vcb_other_usd": "",
            "vcb_usd": "",
            "vnd": "",
            "yen": "",
            "paid": "Debit",
            "note": {},
            "style": {
                HI: { color: "", backgroundColor: "" },
                SI: { color: "", backgroundColor: "" },
                Service: { color: "", backgroundColor: "" },
                Discount: { color: "", backgroundColor: "" },
                Name: { color: "", backgroundColor: "" },
                Room: { color: "", backgroundColor: "" },
                CheckIn: { color: "", backgroundColor: "" },
                CheckOut: { color: "", backgroundColor: "" },
                UnitPrice: { color: "", backgroundColor: "" },
                Telephone: { color: "", backgroundColor: "" },
                Total: { color: "", backgroundColor: "" },
            }
        }
        if (accReportType === "debitModule") table.style = {
            HI: { color: "", backgroundColor: "#ad7ae2" },
            SI: { color: "", backgroundColor: "#ad7ae2" },
            Service: { color: "", backgroundColor: "#ad7ae2" },
            Discount: { color: "", backgroundColor: "#ad7ae2" },
            Name: { color: "", backgroundColor: "#ad7ae2" },
            Room: { color: "", backgroundColor: "#ad7ae2" },
            CheckIn: { color: "", backgroundColor: "#ad7ae2" },
            CheckOut: { color: "", backgroundColor: "#ad7ae2" },
            UnitPrice: { color: "", backgroundColor: "#ad7ae2" },
            Telephone: { color: "", backgroundColor: "" },
            Total: { color: "", backgroundColor: "" }
        }

        if(branchID === 8) {
            table['BT Fer'] = "";
            table['BF & BT Fer'] = "";
        }

        if (branchID === 11) {
            table['BF8'] = "";
            table['BF6'] = "";
            table['MS9'] = "";
            table['MS13_5'] = "";
            table['FM18'] = "";
            table['MSR14'] = "";
            table['MSR19'] = "";
            table['MSR24'] = "";
            table['MSV10'] = "";
            table['MSV15'] = "";
            table['MSV20'] = "";
            table['R5'] = "";
            table['SR12'] = "";
            table['SR10'] = "";
        }

        newData.push(table);

        //  setData(data);
        setData(newData);
        props.onAddNewTab(newData);
    }

    const [typeView, setTypeView] = useState("SUM");

    function checkButtonFooter() {
        if (accReportEdit) {
            if (accReportType === "waiting") {
                return <FooterButton
                    button1="SAVE REPORT"
                    iconBtn1="faSave"
                    onClickButton1={() => onConfirmSave()}
                />
            } else {
                return <FooterButton
                    button1="SAVE REPORT"
                    button2={accReportType !== "debitOnSum" ? "SEND REPORT" : "RESET DATA"}
                    button3="DOWN FORM EXCEL"
                    button4="INPUT FORM EXCEL"
                    iconBtn1="faSave"
                    iconBtn2={accReportType !== "debitOnSum" ? "faPaperPlane" : "faBan"}
                    iconBtn3="faFileDownload"
                    iconBtn4="faFileUpload"
                    onClickButton1={() => onConfirmSave()}
                    onClickButton2={accReportType !== "debitOnSum" ? () => props.onConfirmSentReport(data) : () => props.onResetAccDebitOnSum()}
                    onClickButton3={props.onDownloadFormInput}
                    onClickButton4={props.onUploadFormInput}
                />
            }
        } else {
            return <FooterButton
                button1="REQUEST EDIT"
                button2="DOWN EXCEL"
                iconBtn1="faEdit"
                iconBtn2="faSave"
                onClickButton1={props.onConfirmEdit}
                onClickButton2={props.onDownloadExcel}
            />
        }
    }

    return (
        <React.Fragment>
            {
                (accReportType === "data10" || accReportType === "data20" || accReportType === "month" || accReportType === "debitModule") ?
                    <div className="nav nav-tabs nav-fill survey_report_select mb-3" role="tablist">
                        <span className="nav-item nav-link survey_report_select-item"
                            onClick={() => setTypeView("SUM")}
                            style={{ backgroundColor: '#4caf50', cursor: "pointer", opacity: typeView === "SUM" ? "1" : "0.5" }}>
                            {(accReportType === "data10" || accReportType === "data20" || accReportType === "month")
                                ? "ACC SUM MONTH" : "DEBIT"}
                        </span>
                        <span className="nav-item nav-link survey_report_select-item"
                            onClick={() => setTypeView("DEBIT")}
                            style={{ backgroundColor: '#4caf50', cursor: "pointer", opacity: typeView !== "SUM" ? "1" : "0.5" }}>

                            {(accReportType === "data10" || accReportType === "data20" || accReportType === "month") ? "ACC DEBIT AREA" : "PAID"}
                        </span>
                    </div>
                    : ""
            }

            {typeView === "SUM" ?
                <>
                    <Table
                        columns={columns}
                        hiddenList={hiddenList}
                        data={data}
                        key={data}
                        branchID={branchID}
                        role={role}
                        dayReport={dayReport}
                        isBranchHaveNoMassage={isBranchHaveNoMassage}
                        accReportType={accReportType}
                        sumTotalAccReport={sumTotalAccReport}
                        sumTotalAccDebitMonth={sumTotalAccDebitMonth}
                        listCheckNumberCollect={listCheckNumberCollect}
                        sumTotalAccReportDebit={sumTotalAccReportDebit}
                        onConfirmChangeStyleRow={props.onConfirmChangeStyleRow}

                        updateMyData={updateMyData}
                        updateSumTotal={updateSumTotal}
                        onSelected={onSelected}
                        onSaveSumToServer={onUpdateNoteToServer}
                        changeChecked={changeChecked}

                        onClickOpenHistory={props.onClickOpenHistory}
                        onClickPaid={onClickPaid}
                        onClickHistoryTable={onClickHistoryTable}
                        onClickReturnDebit={onClickReturnDebit}
                        onClickOpenFilterNote={onClickOpenFilterNote}
                        onClickCheckCost={onClickCheckCost}
                        onLoadDataWaitingList={onLoadDataWaitingList}

                        listDelete={listDelete}
                        listCost={accReportType === "month" ? listCost : listCost}
                        hidden={value}
                        accHiddenTab={accHiddenTab}
                        sumTotal={accReportType === "debitModule" ? Items.sumTotalAccReportDebit : sumTotal}

                        accReportEdit={accReportEdit}
                        handleDeleteItem={handleDeleteItem}
                        handCopyItem={handCopyItem}
                        handMoveToDebit={handMoveToDebit}
                    />
                    <button
                        style={{ marginBottom: "40px" }}
                        className={(accReportType === "data10" || accReportType === "data20" || accReportType === "month" || !accReportEdit) ? "op-hidden" : "btn btn__add d-flex"}
                        onClick={addNewTable}
                    >
                        <FontAwesomeIcon icon={faPlus} className="btn__add_icon" />
                        <span className="btn__add_text">Add New Tab</span>
                    </button >
                    {checkButtonFooter()}
                </> :
                (accReportType === "data10" || accReportType === "data20" || accReportType === "month" || accReportType === "debitModule") ?
                    <>
                        <Table
                            columns={columns}
                            hiddenList={hiddenList}
                            data={data2}
                            key={data2}
                            branchID={branchID}
                            role={role}
                            dayReport={dayReport}
                            isBranchHaveNoMassage={isBranchHaveNoMassage}
                            accReportType={accReportType === "debitModule" ? accReportType : "debitMonth"}
                            sumTotalAccReportDebit={sumTotalAccReportDebit}
                            onConfirmChangeStyleRow={props.onConfirmChangeStyleRow}

                            updateMyData={updateMyData}
                            updateSumTotal={updateSumTotal}
                            onSelected={onSelected}
                            onSaveSumToServer={onUpdateNoteToServer}
                            changeChecked={changeChecked}

                            onClickOpenHistory={props.onClickOpenHistory}
                            onClickPaid={onClickPaid}
                            onClickHistoryTable={onClickHistoryTable}
                            onClickReturnDebit={onClickReturnDebit}

                            listDelete={listDelete}
                            listCost={listCost}
                            hidden={value}
                            sumTotal={accReportType === "debitModule" ? Items.sumTotalAccReport : sumTotalAccDebitMonth}

                            accReportEdit={accReportType === "debitModule" ? false : false}
                            handleDeleteItem={handleDeleteItem}
                            handCopyItem={handCopyItem}
                        />
                        {accReportType === "debitModule" ? "" :
                            <FooterButton
                                button1="CONFIRM END MONTH"
                                button2="DOWN EXCEL"
                                iconBtn1="faSave"
                                iconBtn2="faSave"
                                onClickButton1={props.onChangeDebitAreaToDebitModule}
                                onClickButton2={props.onDownloadExcel}
                            />}
                    </>
                    : ""}

            {accReportType === "debitModule" ?
                <FooterButton
                    button1="SAVE REPORT"
                    button2="DOWN FORM EXCEL"
                    button3="INPUT FORM EXCEL"
                    iconBtn1="faSave"
                    iconBtn2="faFileDownload"
                    iconBtn3="faFileUpload"
                    onClickButton1={() => props.onConfirmSave(data.concat(data2))}
                    onClickButton2={props.onDownloadFormInput}
                    onClickButton3={props.onUploadFormInput}
                />
                : ""}
        </React.Fragment >
    )
}

export default AccSumTableItems
